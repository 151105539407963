<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style>
html,
body,
#app{
  width:100%;
  height:100%;

  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #f6f6f6;
}
body{
  margin:0px;
}
</style>
