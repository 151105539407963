import { createRouter, createWebHistory,createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Index',
    component: ()=>import("../views/Home.vue")
  },
  {
    path: '/guess/:userid',
    name: 'Guess',
    component: ()=>import("../views/Guess.vue")
  },
  {
    path: '/login',
    name:'Login',
    component: ()=>import("../views/LoginRegister.vue")
  },
  {
    path: '/:catchAll(.*)',
    name:'404',
    component: ()=>import("../views/404.vue")
  }

]

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes
})

export default router
